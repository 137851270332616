import * as React from "react";

import { fetchUtils, Admin, Resource, ListGuesser, EditGuesser } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import ReceiptIcon from '@material-ui/icons/ReceiptTwoTone';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOnTwoTone';
import LocalGasStation from '@material-ui/icons/LocalGasStationTwoTone';
import { UserList, UserCreate, UserEdit} from './users';
import { CompanyList} from './companies';
import { CancelReasonList, CancelReasonCreate, CancelReasonEdit} from './cancelreason';
import { DispatchCentersList, DispatchCentersCreate, DispatchCentersEdit} from './dispatchcenters';
import { EquipmentTypesList, EquipmentTypesCreate, EquipmentTypesEdit} from './equipmenttypes';
import { FlagReasonsList, FlagReasonsCreate, FlagReasonsEdit} from './flagreasons';
import { OnholdReasonsList, OnholdReasonsCreate, OnholdReasonsEdit} from './onholdreasons';
import { InvoiceStatusList, InvoiceStatusCreate, InvoiceStatusEdit} from './invoicestatus';
import { InvoiceItemsList, InvoiceItemsCreate, InvoiceItemsEdit} from './invoiceitems';
import { InventoryList, InventoryCreate, InventoryEdit} from './inventory';
import { InvoiceItemCategoriesList, InvoiceItemCategoriesCreate, InvoiceItemCategoriesEdit} from './invoiceitemcategories';
import { PrioritiesList, PrioritiesCreate, PrioritiesEdit} from './priorities';
import { SalesTaxCodesList, SalesTaxCodesCreate, SalesTaxCodesEdit} from './salestaxcodes';
import { PaymentModeList, PaymentModeCreate, PaymentModeEdit} from './paymentmodes';
import { PaymentPurposeList, PaymentPurposeCreate, PaymentPurposeEdit} from './paymentpurpose';
import { PaymentStatusList, PaymentStatusCreate, PaymentStatusEdit} from './paymentstatus';
import { PaymentTermsList, PaymentTermsCreate, PaymentTermsEdit} from './paymentterms';
import { SubscriptionStatusList, SubscriptionStatusCreate, SubscriptionStatusEdit} from './subscriptionstatus';
import { SalesTaxList, SalesTaxCreate, SalesTaxEdit} from './salestaxitems';
import { ServicePackagesList, ServicePackagesCreate, ServicePackagesEdit} from './servicepackages';
import { ServiceTeamsList, ServiceTeamsCreate, ServiceTeamsEdit} from './serviceteams';
import { WorkorderCategoriesList, WorkorderCategoriesCreate, WorkorderCategoriesEdit} from './workordercategories';
import { RepairCategoriesList, RepairCategoriesCreate, RepairCategoriesEdit} from './repaircategories';
import { RepairCodesList, RepairCodesCreate, RepairCodesEdit} from './repaircodes';
import { InvoiceItemsQtyPricingList, InvoiceItemsQtyPricingCreate, InvoiceItemsQtyPricingEdit} from './invoiceitemsqtypricing';
import { WorkorderCategoryItemsList, WorkorderCategoryItemsCreate, WorkorderCategoryItemsEdit} from './workordercategoryitems';
import { WorkorderStatusList, WorkorderStatusCreate, WorkorderStatusEdit} from './workorderstatus';
import { WorkorderTypesList, WorkorderTypesCreate, WorkorderTypesEdit} from './workordertypes';
import { WorkzonesList, WorkzonesCreate, WorkzonesEdit} from './workzones';
import { SuppliersList, SuppliersCreate, SuppliersEdit} from './suppliers';
import { PurchaseOrdersList, PurchaseOrdersCreate, PurchaseOrdersEdit} from './purchaseorders';
import PersonIcon from '@material-ui/icons/PersonTwoTone';
import { useKeycloak } from '@react-keycloak/web';
import Dashboard from './Dashboard';
import MyLogoutButton from './MyLogoutButton';
import MyLayout from './MyLayout';
import myTheme from './myTheme';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentationTwoTone';
import DomainIcon from '@material-ui/icons/DomainTwoTone';
import BuildIcon from '@material-ui/icons/BuildTwoTone';
import FlagIcon from '@material-ui/icons/FlagTwoTone';
import PausePresentationIcon from '@material-ui/icons/PausePresentationTwoTone';
import PriorityHighIcon from '@material-ui/icons/PriorityHighTwoTone';
import ListAltIcon from '@material-ui/icons/ListAltTwoTone';
import GroupIcon from '@material-ui/icons/GroupTwoTone';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayListTwoTone';
import ExploreIcon from '@material-ui/icons/ExploreTwoTone';
import CategoryIcon from '@material-ui/icons/CategoryTwoTone';
import ExtensionIcon from '@material-ui/icons/ExtensionTwoTone';
import LocationOnIcon from '@material-ui/icons/LocationOnTwoTone';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarmTwoTone';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevicesTwoTone';
import FormatListBulletedTwoToneIcon from '@material-ui/icons/FormatListBulletedTwoTone';
import FormatListNumberedTwoToneIcon from '@material-ui/icons/FormatListNumberedTwoTone';
import DnsTwoToneIcon from '@material-ui/icons/DnsTwoTone';
import LocalAtmTwoToneIcon from '@material-ui/icons/LocalAtmTwoTone';
import DoneAllTwoToneIcon from '@material-ui/icons/DoneAllTwoTone';
import dataProvider from './dataProvider';


const knownResources = [
    <Resource options={{ label: 'Asset Types' }} name="equipmenttypes"  icon={ImportantDevicesIcon} list={EquipmentTypesList} create={EquipmentTypesCreate} edit={EquipmentTypesEdit} />,
    <Resource options={{ label: 'Cancel Reasons' }} name="cancelreasons"  icon={CancelPresentationIcon} list={CancelReasonList} create={CancelReasonCreate} edit={CancelReasonEdit} />,
    <Resource options={{ label: 'Dispatch Centers' }} name="dispatchcenters"  icon={DomainIcon} list={DispatchCentersList} create={DispatchCentersCreate} edit={DispatchCentersEdit} />,
    <Resource options={{ label: 'Flag Reasons' }} name="flagreasons"  icon={FlagIcon} list={FlagReasonsList} create={FlagReasonsCreate} edit={FlagReasonsEdit} />,
    <Resource options={{ label: 'Hold Reasons' }} name="onholdreasons"  icon={PausePresentationIcon} list={OnholdReasonsList} create={OnholdReasonsCreate} edit={OnholdReasonsEdit} />,
    <Resource options={{ label: 'Invoice Items' }} name="invoiceitems"  icon={FormatListBulletedTwoToneIcon} list={InvoiceItemsList} create={InvoiceItemsCreate} edit={InvoiceItemsEdit} />,
    <Resource options={{ label: 'Qty Pricing' }} name="invoiceitemsqtypricing"  icon={DoneAllTwoToneIcon} list={InvoiceItemsQtyPricingList} create={InvoiceItemsQtyPricingCreate} edit={InvoiceItemsQtyPricingEdit} />,
    <Resource options={{ label: 'Inventory' }} name="inventory"  icon={CategoryIcon} list={InventoryList}  edit={InventoryEdit} />,
    <Resource options={{ label: 'Invoice Item Categories' }} name="invoiceitemcategories"  icon={CategoryIcon} list={InvoiceItemCategoriesList} create={InvoiceItemCategoriesCreate} edit={InvoiceItemCategoriesEdit} />,
    <Resource options={{ label: 'Invoice Status' }} name="invoicestatus"  icon={ReceiptIcon} list={InvoiceStatusList} create={InvoiceStatusCreate} edit={InvoiceStatusEdit} />,
    <Resource options={{ label: 'Locations' }} name="companies"  icon={LocationOnIcon} list={CompanyList} />,
    <Resource options={{ label: 'Priorities' }} name="priorities"  icon={PriorityHighIcon} list={PrioritiesList} create={PrioritiesCreate} edit={PrioritiesEdit} />,
    <Resource options={{ label: 'Packages' }} name="servicepackages"  icon={ListAltIcon} list={ServicePackagesList} create={ServicePackagesCreate} edit={ServicePackagesEdit} />,
    <Resource options={{ label: 'Repair Categories' }} name="repaircategories"  icon={CategoryIcon} list={RepairCategoriesList} create={RepairCategoriesCreate} edit={RepairCategoriesEdit} />,
    <Resource options={{ label: 'Repair Codes' }} name="repaircodes"  icon={BuildIcon} list={RepairCodesList} create={RepairCodesCreate} edit={RepairCodesEdit} />,
    <Resource options={{ label: 'Sales Tax Codes' }} name="salestaxcodes"  icon={DnsTwoToneIcon} list={SalesTaxCodesList} create={SalesTaxCodesCreate} edit={SalesTaxCodesEdit} />,
    <Resource options={{ label: 'Tax Rates' }} name="salestaxitems"  icon={LocalAtmTwoToneIcon} list={SalesTaxList} create={SalesTaxCreate} edit={SalesTaxEdit} />,
    <Resource options={{ label: 'Teams' }} name="serviceteams"  icon={GroupIcon} list={ServiceTeamsList} create={ServiceTeamsCreate} edit={ServiceTeamsEdit} />,
    <Resource options={{ label: 'Users' }} name="users"  icon={PersonIcon} list={UserList}  edit={UserEdit} create={UserCreate} />,
    <Resource options={{ label: 'WO Categories' }} name="workordercategories"  icon={CategoryIcon} list={WorkorderCategoriesList} create={WorkorderCategoriesCreate} edit={WorkorderCategoriesEdit} />,
    <Resource options={{ label: 'WO Category Items' }} name="workordercategoryitems"  icon={FormatListNumberedTwoToneIcon} list={WorkorderCategoryItemsList} create={WorkorderCategoryItemsCreate} edit={WorkorderCategoryItemsEdit} />,
    <Resource options={{ label: 'WO Status' }} name="workorderstatus"  icon={AccessAlarmIcon} list={WorkorderStatusList} create={WorkorderStatusCreate} edit={WorkorderStatusEdit} />,
    <Resource options={{ label: 'WO Types' }} name="workordertypes"  icon={FeaturedPlayListIcon} list={WorkorderTypesList} create={WorkorderTypesCreate} edit={WorkorderTypesEdit} />,
    <Resource options={{ label: 'Zones' }} name="workzones"  icon={ExploreIcon} list={WorkzonesList} create={WorkzonesCreate} edit={WorkzonesEdit} />,
    <Resource options={{ label: 'Payment Modes' }} name="paymentmodes"  icon={ExploreIcon} list={PaymentModeList} create={PaymentModeCreate} edit={PaymentModeEdit} />,
    <Resource options={{ label: 'Payment Purpose' }} name="paymentpurpose"  icon={ExploreIcon} list={PaymentPurposeList} create={PaymentPurposeCreate} edit={PaymentPurposeEdit} />,
    <Resource options={{ label: 'Payment Status' }} name="paymentstatus"  icon={ExploreIcon} list={PaymentStatusList} create={PaymentStatusCreate} edit={PaymentStatusEdit} />,
    <Resource options={{ label: 'Payment Terms' }} name="paymentterms"  icon={ExploreIcon} list={PaymentTermsList} create={PaymentTermsCreate} edit={PaymentTermsEdit} />,
    <Resource options={{ label: 'Purchase Orders' }} name="purchaseorders"  icon={ExploreIcon} list={PurchaseOrdersList} create={PurchaseOrdersCreate} edit={PurchaseOrdersEdit} />,
    <Resource options={{ label: 'Subscription Status' }} name="subscriptionstatus"  icon={ExploreIcon} list={SubscriptionStatusList} create={SubscriptionStatusCreate} edit={SubscriptionStatusEdit} />,
    <Resource options={{ label: 'Suppliers' }} name="suppliers"  icon={ExploreIcon} list={SuppliersList} create={SuppliersCreate} edit={SuppliersEdit} />,

];

export default () => {
  const {keycloak, initialized} = useKeycloak();
  const httpClient = (url, options = {}) => {
      if (!options.headers) {
          options.headers = new Headers({ Accept: 'application/json' });
      }
      const token = localStorage.getItem('react-token');
      options.headers.set('Authorization', `Bearer ${keycloak.token}`);
      options.headers.set('Origin', `http://localhost:3000`);
      return fetchUtils.fetchJson(url, options);
  };

  return (
  // <Admin theme={myTheme} layout={MyLayout}  title="WorkOrder Admin" dataProvider={dataProvider('https://woapi.gstinc.link/api/vvt',httpClient)} dashboard={Dashboard}>
  <Admin disableTelemetry theme={myTheme} layout={MyLayout}  title="WorkOrder Admin" dataProvider={dataProvider('https://woapi.gstinc.link/api',httpClient)} dashboard={Dashboard}>
  {knownResources}
  </Admin>)
}
